<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- user list -->
            <section class="published-jobs section-gap-30">
                <!-- Job Search -->
                <JobSearch @searched="searchJobs"></JobSearch>

                <!--Page Title-->

                <div class="section-title-wrap">
                    <h2 class="section-title text-capitalize">{{ $t(`draft jobs`) }}</h2>
                </div>

                <div class="job-sections-wowax" v-if="isContentLoading">

                    <h4 class="empty-message" v-if="isEmpty">
                        {{ $t(`No Draft Jobs Found`) }}
                    </h4>

                    <div class="row row-cols-1 row-cols-lg-2" v-else>
                        <div class="col" v-for="job  in draftJobs">
                            <JobCard @jobDeleted="jobDeleted" :job="job" :options="options"></JobCard>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <infinite-loading v-if="totalJob > 6" :identifier="infiniteKey" spinner="waveDots" @infinite="loadJobPosts">
                                <div slot="no-more"></div>
                                <div slot="no-results"></div>
                            </infinite-loading>
                        </div>
                    </div>
                </div>

                <div class="job-sections-wowax" v-else>
                    <div class="row row-cols-1 row-cols-lg-2">
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                        <div class="col">
                            <JobCardLoader></JobCardLoader>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>

import InfiniteLoading from "vue-infinite-loading";

const DashboardLayout = () => import("../../layouts/DashboardLayout.vue");
const JobSearch = () => import("../../components/jobs/JobSearch.vue");
const JobCard = () => import("../../components/jobs/JobCard.vue");
const Pagination = () => import("../../components/common/Pagination.vue");
import client from '../../app/api/Client';

const JobCardLoader = () => import("../../components/_loaders/JobCardLoader.vue");

export default {
    components: {
        DashboardLayout,
        JobSearch,
        JobCard,
        Pagination,
        JobCardLoader,
        InfiniteLoading
    },
    data() {
        return {
            isContentLoading: false,
            draftJobs: [],
            options: {
                pipeline: false,
                candidates: false,
                delete: true,
                edit: true,
                duplicate: true,
                more: false,
                moreView: false,
                moreShare: false,
                moreDuplicate: false
            },

            page: 2,
            lastPage: null,
            infiniteKey: new Date(),
            form: {
                search: ''
            },
            totalJob: null
        }
    },
    computed: {
        isEmpty() {
            return this.draftJobs.length === 0;
        }
    },
    methods: {

        async loadDraftJobs() {
            this.isContentLoading = false;

            let {data: {data}} = await client().get('/job/draft');
            this.draftJobs = data.data;
            this.lastPage = data.last_page;
            this.totalJob = data.total;

            this.isContentLoading = true;
        },

        async loadJobPosts($state) {
            try {
                let queryParams = {params: {page: this.page, ...this.form}};

                let {data: {data}} = await client().get('/job/draft', queryParams);
                this.page = data.current_page+1;
                this.lastPage = data.last_page;

                this.draftJobs.push(...data.data);
            } catch (e) {

            }

            $state.loaded();
            if (this.page > this.lastPage) {
                $state.complete();
            }
        },

        async jobDeleted(job) {
            this.draftJobs = this.draftJobs.filter(dj => dj.id !== job.id);
        },

        async searchJobs(form) {
            try {
                let queryParams = {params: {page: 1, search: form.search}};

                let {data: {data}} = await client().get('/job/draft', queryParams);
                this.page = data.current_page+1;
                this.lastPage = data.last_page;
                this.totalJob = data.total;
                this.infiniteKey = new Date();

                this.draftJobs = data.data;
            } catch (e) {

            }
        }

    },
    async created() {
        await this.loadDraftJobs();
    }
}
</script>

<style>
.dropdown-menu.show {
    z-index: 985 !important;
}
</style>
